<template>
  <div class="races-betting-area-a">
    <Tabs :tabs="tabs"
          :activeTab="activeTab"
          v-bind="$attrs"
          @change="updateTab"></Tabs>
     <component v-bind:is="component.component"
               v-on="$listeners"
               v-bind="$attrs"
               @change="addBet"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Tabs, eventBus } from '@nsftx/games-sdk-js';

export default {
  name: 'RacesBettingAreaA',
  inheritAttrs: false,
  components: {
    Tabs,
  },
  data() {
    return {
      activeTab: {},
      bettingComponents: {},
    };
  },
  computed: {
    ...mapGetters({
      translations: 'translations',
    }),
    ...mapGetters([
      'config',
      'offer',
      'isTerminal',
    ]),
    component() {
      return this.activeTab;
    },
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateTab(tab) {
      this.activeTab = tab;
      this.$emit('tab-changed');
    },
    simulateClick() {
      if (this.isTerminal) {
        const simulateElement = this.$refs.simulateClickElement;
        simulateElement.click();
      }
    },
    addBet(bet) {
      this.simulateClick();
      eventBus.$emit('BetRemoved');
      const betTypes = {
        0: 'selectRaceCard',
        1: 'selectRaceCard',
        2: 'selectRaceCard',
        10: 'selectForecast',
        12: 'selectForecast',
        14: 'selectH2H',
      };
      this.$emit(betTypes[bet.betTypeId], bet);
    },
  },
};
</script>
