<template>
  <div class="forecast-grid-wrapper">
    <div class="top-racer-labels">
      <span class="top-left-label">-</span>
      <div class="colored-labels" v-if="!isMobile">
        <RacerLabel
          v-for="(racer, index) in $attrs.racers"
          :ordinalNumber="racer"
          :color="$attrs.colors[index]"
          height="40"
          orientation="bottom"
          :key="index">
        </RacerLabel>
      </div>
    </div>
    <div class="bet-wrapper">
      <div class="left-racer-labels" v-if="!isMobile">
        <RacerLabel
          v-for="(racer, index) in $attrs.racers"
          :ordinalNumber="racer"
          :color="$attrs.colors[index]"
          height="40"
          orientation="right"
          :key="index">
        </RacerLabel>
      </div>
      <div class="forecast-bets-wrapper" v-if="!reverseForecastActive">
        <div class="forecast-bets"
          v-for="(row, key) in $attrs.forecastOdds"
          :key="key">
           <Button
             v-for="(odd, index) in row"
             :key="index"
             :class="{disabled: odd.empty || $attrs.bettingDisabled}"
             :disabled="odd.empty || $attrs.bettingDisabled"
             :variation="isActive(odd) && !odd.reverse && odd.betTypeId === 10 ?
              'brand' : 'default'"
             @change="selectOdd(getButton(key, index))"
             :label="odd.empty ? getLabel : formatOdd(odd.value)">
             <div class="label-wrapper" v-if="isMobile">
              <RacerLabel v-if="odd.empty"
                :ordinalNumber="index"
                :color="$attrs.colors[index - 1]"
                height="40"
                orientation="right">
              </RacerLabel>
             </div>
          </Button>
        </div>
      </div>
      <div class="forecast-bets-wrapper" v-if="reverseForecastActive">
        <div class="forecast-bets"
             v-for="(row, key) in $attrs.reverseForecastOdds"
             :key="key">
          <Button
            v-for="(odd, index) in row"
            :key="index"
            :class="{disabled: odd.empty || $attrs.bettingDisabled}"
            :disabled="odd.empty || $attrs.bettingDisabled"
            :variation="isActive(odd) && odd.reverse && odd.betTypeId === 12
              ? 'brand' : 'default'"
            @change="selectOdd(getButton(key, index))"
            :label="odd.empty ? getLabel : formatOdd(odd.value)">
            <div class="label-wrapper" v-if="isMobile">
              <RacerLabel v-if="odd.empty"
                :ordinalNumber="index"
                :color="$attrs.colors[index - 1]"
                height="40"
                orientation="right">
              </RacerLabel>
             </div>
          </Button>
        </div>
      </div>
    </div>
    <div class="reverse-wrapper">
      <Button :label="reverseForecastLabel"
              :variation="reverseForecastActive ? 'brand' : 'default'"
              @change="toggleReverse">
      </Button>
    </div>
  </div>
</template>

<script>
import { find, each } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { Button } from '@nsftx/games-sdk-js';
import RacerLabel from '@/components/RacerLabel';

export default {
  name: 'ForecastGrid',
  components: {
    Button,
    RacerLabel,
  },
  data() {
    return {
      selectedForecastBets: null,
      selectedReverseForecastBets: null,
    };
  },
  computed: {
    ...mapGetters([
      'config',
      'reverseForecast',
      'reverseForecastActive',
      'gamesBetslip/ticket',
      'translations',
    ]),
    reverseForecastLabel() {
      return `${this.translations.greyhound_reverse}`;
    },
    forecastLabel() {
      return this.translations.greyhound_forecast;
    },
    isMobile() {
      return window.innerWidth < 640;
    },
    getLabel() {
      if (this.isMobile) {
        return '';
      }
      return '-';
    },
  },
  methods: {
    ...mapActions([
      'setIsReverseForecastActive',
    ]),
    formatOdd(value) {
      if (value < 10) {
        return value.toFixed(2);
      }
      if (value >= 10 && value < 100) {
        return value.toFixed(1);
      }
      return value.toFixed(0);
    },
    toggleReverse() {
      this.setIsReverseForecastActive(!this.reverseForecastActive);
    },
    getButton(rowIndex, columnIndex) {
      return this.$attrs.forecastOdds[rowIndex][columnIndex];
    },
    getReverseButton(rowIndex, columnIndex) {
      return this.$attrs.forecastOdds[rowIndex][columnIndex];
    },
    selectOdd(odd) {
      if (this.reverseForecastActive) {
        this.selectReverseForecastOdd(odd, odd.displayId);
      } else {
        this.selectForecastOdd(odd);
      }
    },
    selectReverseForecastOdd(odd) {
      const forecastOdd = odd;
      const reverseBetId = odd.displayId.split('').reverse().join('');
      forecastOdd.betTypeId = 12;
      forecastOdd.reverse = true;
      forecastOdd.round = odd.round;
      forecastOdd.roundNumber = odd.round;
      forecastOdd.outcome = this.reverse ? `${this.reverseForecastLabel} ${this.forecastLabel.toLowerCase()}` : this.forecastLabel;
      each(this.$attrs.reverseForecastOdds, (betRow) => {
        each(betRow, (bet) => {
          if (bet.displayId === reverseBetId) {
            forecastOdd.reverseOddValue = bet.value;
          }
        });
      });
      this.$emit('change', forecastOdd);
    },
    selectForecastOdd(odd) {
      const forecastOdd = odd;
      forecastOdd.outcome = this.reverse ? `${this.reverseForecastLabel} ${this.forecastLabel.toLowerCase()}` : this.forecastLabel;
      this.$emit('change', forecastOdd);
    },
    isActive(odd) {
      const oddsToCheck = odd.reverse ? this.$attrs.activeReverseBets : this.selectedForecastBets;
      const betTypeId = odd.reverse ? 12 : 10;
      const value = !!find(oddsToCheck,
        {
          displayId: odd.displayId,
          round: odd.round,
          betTypeId,
          reverse: odd.reverse,
        });
      return value;
    },
  },
  updated() {
    this.selectedForecastBets = this.$attrs.activeButtons;
  },
  mounted() {
    this.selectedForecastBets = this.$attrs.activeButtons;
  },
};
</script>

<style scoped lang="scss">
  .forecast-grid-wrapper {
    background-color: var(--card);
    width: 100%;
    padding: 16px 8px 8px 8px;
    .reverse-wrapper {
      padding: 8px 0px 8px 0px;
      width: 100%;
      .button {
        font-family: Roboto;
      }
    }
    .top-racer-labels {
      display: flex;
      .top-left-label {
        width: 20%;
        visibility: hidden;
      }
      .colored-labels {
        display: flex;
        flex-direction: row;
        width: 100%;
        .racer-label-wrapper {
          width: 100%;
          margin: 1px;
        }
      }
    }
    .left-racer-labels {
      display: flex;
      flex-direction: column;
      width: 20%;
      div {
        margin: 1px;
      }
    }
    .bet-wrapper {
      display: flex;
      .forecast-bets-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 0;
        .forecast-bets {
          display: flex;
          button {
            flex-grow: 1;
            width: 0;
            margin: 1px;
            padding: 0;
          }
        }
      }
    }
    .reverse-forecast-wrapper {
      font-family: "Roboto", sans-serif;
      text-align: right;
      padding-right: 5px;
      padding-bottom: 5px;
      height: 35px;
    }
  }
  @media all and (max-width: 640px) {
    .forecast-grid-wrapper {
      .reverse-wrapper {
        .button {
          font-size: 14px;
        }
      }
      .button {
        font-size: 10px;
      }
      .top-racer-labels {
        .top-left-label {
          width: 40px;
          margin-right: 5px;
        }
      }
      .bet-wrapper {
        .left-racer-labels {
          width: 40px;
          .racer-label-wrapper {
            width: 40px;
          }
        }
      }
    }
  }
</style>
